import React, { Component } from 'react';
import ApplicationForm from './ApplicationForm';
import { connect } from 'react-redux';
// import ReactGA from 'react-ga';
import { submitDriverRequest } from './ApplicationForm.actions';
// import { navigate } from 'gatsby';

class ApplicationFormContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: '',
      firstName: '',
      lastName: '',
      email: '',
      makeModel: '',
      city: '',
      registration: '',
      nationalinsurancenum: '',
      drivlicensenum: '',
      // dimW: '',
      // tons: '',
      vanSize: 'Medium',
      postcode: ''
    }
  }

  onChangeForm = (e, field) => {
    var obj = {};
    obj[field] = e.target.value;
    this.setState(obj);
  };

  handleSizeChange = (e) => {
    this.setState({ vanSize: e.target.value });
  }

  submitClick = () => {
    this.props.submit(this.state);
  }

  render() {
    return (
      <ApplicationForm
        {...this.props}
        onChangeForm={this.onChangeForm}
        handleSizeChange={this.handleSizeChange}
        submitClick={this.submitClick}
        error={this.props.driverForm.error}
        success={this.props.driverForm.success}
      />
    )
  }
}

function mapStateToProps(state) {
  return {
    driverForm: state.driverForm
  }
}

function mapDispatchToProps(dispatch) {
  return {
    submit: (containerState) => dispatch(submitDriverRequest(containerState))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationFormContainer);

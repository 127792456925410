import { StyleSheet } from 'aphrodite';
import COLORS from '../../styles/colors';
import QUERIES from '../../styles/queries';

const styles = StyleSheet.create({
  root: {
    [QUERIES.desktop]: {
      padding: 16
    },
  },
  titleArea: {
    textAlign: 'center',
    paddingTop: 12,
    paddingBottom: 32,
    [QUERIES.mobile]: {
      maxWidth: 300,
      margin: '0 auto'
    },
  },
  applicationHead: {
    color: COLORS.jjdGreyish,
    fontWeight: 600,
  },
  applicationPara: {
    color: COLORS.jjdGreyish,
  },
  signupBox: {
    flex: 1
  },
  signupContent: {
    background: COLORS.white,
    borderRadius: 8,
    overflow: 'hidden',
    marginBottom: 64,
    marginTop: 32,
    maxWidth: 800,
    boxShadow: '0 16px 40px rgba(0,0,0,0.12)',
    [QUERIES.desktop]: {
      marginTop: 64,
      margin: '32px auto',
    },
    margin: '0 auto',
  },
  input: {
    width: '100%',
    padding: 32,
    border: 'none',
    borderBottom: '1px solid whitesmoke',
    borderColor: '#e6e6e6',
    color: COLORS.jjdGreyish,
    fontWeight: 'noraml'
  },
  select: {
    width: '50%',
    padding: 64,
    border: 'none',
    borderBottom: '1px solid whitesmoke',
    borderColor: '#e6e6e6',
    color: COLORS.offBlack,
    fontWeight: '700',
    fontSize: 18,
    align: 'flex-start'
  },
  selectTitle: {
    textAlign: 'left',
    paddingLeft: 32,
    fontWeight: 700
  },

  errorMessage: {
    color: COLORS.hotPink,
    fontSize: 18,
    paddingLeft: 24,
    paddingTop: 12,
    lineHeight: '39px',
    margin: 0,
    fontWeight: '600'
  }
});

export default styles;

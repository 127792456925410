import React from 'react';
import Layout from '../containers/gatsby/layout';
import ApplicationFormContainer from '../containers/drivers/ApplicationFormContainer';
import SEO from '../containers/gatsby/seo';

const DriversPage = (props) => (
  <Layout location={props.location}>
    <SEO title={'Become a JJD Driver'}/>
    <ApplicationFormContainer/>
  </Layout>
);

export default DriversPage;

import React from 'react';
import styles from './ApplicationForm.styles';
// import TextField from '@material-ui/core/TextField';
import { css } from 'aphrodite';
// import STYLES from '../../styles/shared';
// import BookingPriceContainer from '../BookingPrice/PriceContainer';
import PrimaryButton from '../../components/buttons/PrimaryButton/PrimaryButton';
import COLORS from '../../styles/colors';

const ApplicationForm = (props) => (
  <div className={css(styles.root)}>
    <div className={css(styles.content)}>
      <div className={css(styles.titleArea)}>
        <h1 className={css(styles.applicationHead)}>Driver Signup</h1>
        <p className={css(styles.applicationPara)}>If you are a van driver and want to join the JJD platform then fill in the form below and we will be in touch</p>

        <div className={css(styles.signupBox)}>
          <div className={css(styles.signupContent)}>
            <input placeholder={'First name'} className={css(styles.input, styles.firstInput)} onChange={(e) => props.onChangeForm(e, 'firstName')} value={props.firstName} />
            <input placeholder={'Last name'} className={css(styles.input)} onChange={(e) => props.onChangeForm(e, 'lastName')} value={props.lastName} />
            <input placeholder={'Email'} type={'email'} className={css(styles.input)} onChange={(e) => props.onChangeForm(e, 'email')} value={props.email} />
            <input placeholder={'Phone number'} type="text" pattern="[0-9]*" className={css(styles.input)} onChange={(e) => props.onChangeForm(e, 'phone')} value={props.phone} />
            <input placeholder={'Make Model'} className={css(styles.input)} onChange={(e) => props.onChangeForm(e, 'makeModel')} value={props.makeModel} />
            <input placeholder={'City'} className={css(styles.input)} onChange={(e) => props.onChangeForm(e, 'city')} value={props.city} />
            <input placeholder={'Home Postcode (for improved job allocation)'} className={css(styles.input)} onChange={(e) => props.onChangeForm(e, 'postcode')} value={props.postcode} />
            <input placeholder={'Registration Plate'} className={css(styles.input)} onChange={(e) => props.onChangeForm(e, 'registration')} value={props.registration} />
            <input placeholder={'National insurance number'} className={css(styles.input)} onChange={(e) => props.onChangeForm(e, 'nationalinsurancenum')} value={props.nationalinsurancenum} />
            <input placeholder={'Driving license number'} className={css(styles.input)} onChange={(e) => props.onChangeForm(e, 'drivlicensenum')} value={props.drivlicensenum} />
            {/* <input placeholder={'Internal storage width / (m)'} className={css(styles.input)} onChange={(e) => props.onChangeForm(e, 'dimW')} value={props.dimW} />
            <input placeholder={'Weight capacity / (tons)'} className={css(styles.input)} onChange={(e) => props.onChangeForm(e, 'tons')} value={props.tons} /> */}
            <div>
              <p className={css(styles.selectTitle)}>Van size</p>
              <select className={css(styles.select)} value={props.vanSize} onChange={props.handleSizeChange}>
                <option value={'Small'}>Small</option>
                <option value={'Medium'}>Medium</option>
                <option value={'Long'}>Long</option>
                <option value={'Luton'}>Luton</option>
              </select>
            </div>
            {props.error && <p className={css(styles.errorMessage)}>
              {props.error}
            </p>}
            {props.success && <p>Form submission successful</p>}
            {!props.success &&
              <div style={{ display: 'flex', justifyContent: 'flex-end', padding: 32 }}>
                <PrimaryButton color={COLORS.jjdRed} title={'Submit Form'} large={true} onClick={props.submitClick} />
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ApplicationForm;